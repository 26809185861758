<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">To Do</h3>
          <div class="row">
            <div class="col-xs-12 col-md-6 mb-4" align="left">
              <label for="member" class="form-label">Member</label>
              <Multiselect v-model="MultiSelectMembers.value" v-bind="MultiSelectMembers" @select="SelectedMember" @clear="ClearMember" class="size-multiselect">
                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.icon">
                    <a :style="{'color':option.color}">{{ option.label }}</a>
                    <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                      <span class="multiselect-tag-remove-icon"></span>
                    </span>
                  </div>
                </template>
                <template v-slot:option="{ option }">
                  <img class="character-option-icon" :src="option.icon"> {{ option.label }}
                </template>
              </Multiselect>
            </div>
            <div class="col-md-6 mb-4" align="left">
              <label for="project" class="form-label">Project</label>
              <Multiselect v-model="MultiSelectProject.value" v-bind="MultiSelectProject" @select="SelectedPostMember" ref="multiselect" class="size-multiselect"></Multiselect>
            </div>
          </div>
          <div style="display:inline !important">
            <div class="d-flex justify-content-between">
              <h2 class="card-title ">
                <button class="button-1 w-100 btn btn-md btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"> Add To Do</button>
              </h2>
              <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">New To Do</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <form ref="ProjectForm" @submit.prevent="PostAddTodo()" method="post" class="row" align="left">
                        <div class="col-md-12">
                          <label for="project" class="form-label">Member</label>
                          <div class="col-lg-12 col-xs-12 col-sm-12 text-start mt-2">
                            <div class="col-lg-12 col-xs-12 col-sm-12 text-start mt-2">
                              <Multiselect v-model="MultiSelectMembers.value" v-bind="MultiSelectMembers" @select="SelectedMember" @clear="ClearMember" class="size-multiselect">
                                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                  <div class="multiselect-tag is-user">
                                    <img :src="option.icon">
                                    <a :style="{'color':option.color}">{{ option.label }}</a>
                                    <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                                      <span class="multiselect-tag-remove-icon"></span>
                                    </span>
                                  </div>
                                </template>
                                <template v-slot:option="{ option }">
                                  <img class="character-option-icon" :src="option.icon"> {{ option.label }}
                                </template>
                              </Multiselect>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <label for="project" class="form-label">Project</label>
                          <div class="col-lg-12 col-xs-12 col-sm-12 text-start mt-2">
                            <div class="col-lg-12 col-xs-12 col-sm-12 text-start mt-2">
                              <Multiselect v-model="MultiSelectProject.value" v-bind="MultiSelectProject" ref="multiselect" class="size-multiselect"></Multiselect>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mt-3">
                          <label for="project" class="form-label">To Do</label>
                          <input type="text" v-model="TodoPost.todo" class="form-control" required />
                        </div>
                        <div class="mb-3 mt-3">
                          <label for="project" class="form-label">Description</label>
                          <textarea v-model="TodoPost.description" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                        <div class="col-md-12 mt-3"></div>
                        <div class="modal-footer">
                          <button type="submit" :disabled="Form.submit" class="button-1 btn btn-primary">Save changes</button>
                          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Tabla-->
          <div v-if="ListTodo.status != 'false'" class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div class="">
                <table  id="todo-table" class="table table-striped  responsive">
                  <thead>
                    <tr class="text-start">
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Todo</span>
                      </th>
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Project</span>
                      </th>
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Date</span>
                      </th>
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Status</span>
                      </th>
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="mb-special" v-if="ListTodo.status != 'false'">
                    <tr class="text-start" v-for="(obj, key, index) in ListTodo.list" :key="index">
                      <td class="text-start">{{obj.todo}}</td>
                      <td class="text-start">{{ obj.project }}</td>
                      <td class="text-start">{{obj.date}}</td>
                      <td class="text-start">
                        <p v-if="obj.status == '1'" class="special-badget-status">Finished</p>
                        <p v-if="obj.status == '0'" class="special-badget-status-inactive">In progress</p>
                      </td>
                      <td class="text-center">
                        <div class="d-flex justify-content-around">
                          <a href="#" class="text-blue" id="dropdownMenuActions" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                          </a>
                          <ul class="drop-actions dropdown-menu" aria-labelledby="dropdownMenuActions">
                            <li>
                              <router-link :to="'todo/edit/'+obj.id" class="dropdown-item rounded">Edit ToDo</router-link>
                            </li>
                            <li>
                              <a class="dropdown-item" v-on:click="DeleteTodo(obj.id)">Delete To Do</a>
                              <a v-if="obj.status == '0'" class="dropdown-item" v-on:click="UpdateTodoStatus(obj.id,obj.status)">Mark as completed</a>
                              <a v-if="obj.status == '1'" class="dropdown-item" v-on:click="UpdateTodoStatus(obj.id,obj.status)">Mark as incomplete</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- Paginador -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request'; 
  import Multiselect from '@vueform/multiselect'
  import "datatables.net-dt/js/dataTables.dataTables"
  import "datatables.net-dt/css/jquery.dataTables.min.css"
  import $ from 'jquery'; 

  export default
  {
    name: 'ToDo',
    components: 
    {
      Multiselect,
    },
    data: () => (
    { 
      show:true, 
      TodoPost: 
      {
        date: "",
        id_session: "",
        project: "",
        todo: "",
        description: ""
      },
      TodoPostList: 
      {
        id_session: "1",
        project: "1",
      },
      ListTodo: 
      {
        active: "0",
        status:"false",
        list: {}
      },
      Form: 
      {
        submit: false,
      },
      MultiSelectProject: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        hideSelected: true,
        showOptions: true,
        required: true
      },
      MultiSelectMembers: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        createTag: false,
        inputType: "email",
        required: false,
        placeholder: "example@domain.com",
        hideSelected: true,
        showOptions: true,
        required: true
      },
      ListMembers: 
      {
        status: "false",
        list: {}
      },
      SelectMember:
      {
        id_session:"",
      }
    }),
    methods: 
    {
      PostAddTodo() 
      {
        this.Form.submit = true

        let date = new Date();
        this.TodoPost.date = Math.floor(date.getTime() / 1000);

        this.TodoPost.id_session = this.MultiSelectMembers.value
        this.TodoPost.project = this.MultiSelectProject.value

        $('#todo-table').DataTable().destroy()

        Api.post("/todo/register", this.TodoPost).then((result) => 
        {
          if(result.data.status == "true")
          {
            this.$moshaToast( ''+this.$filters.OnlyLetters(result.data.msg)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
         
            this.$nextTick(function() 
            {
              setTimeout( () => 
              {
                //this.MultiSelectMember.value = []
                //this.MultiSelectProject.value = []
                this.TodoPost.todo = ""
                this.TodoPost.description = ""
                this.Form.submit = false
              }, 5000);
                        
              this.SelectedPostMember() 
            })
          }

          if(result.data.status == "false")
          {
            this.$moshaToast( ''+this.$filters.OnlyLetters(result.data.msg)+'', { type: 'default', position:'bottom-right', transition:'zoom' })

            setTimeout( () => 
            {
              this.Form.submit = false
            }, 5000);
          } 
        })    
      },
      GetTodo() 
      {
        if(this.MultiSelectProject.value != "")
        {
          Api.post("/todo/list/team",this.TodoPostList).then((result) => 
          {
            this.ListTodo.status = result.data.status

            if(this.ListTodo.status == "true")
            {
              this.ListTodo.list = result.data.todos;
              
              this.$nextTick(() => 
              {
                if(this.ListTodo.active == 0)
                {
                  this.InitDatatable()
                  this.ListTodo.active = "1"
                }

                if(this.ListTodo.active == 1)
                {
                   this.InitDatatable()
                }
              })
            }
          })
        }
      },
      InitDatatable() 
      {
        var table = $('#todo-table').DataTable( 
        {
          dom: 'lftip',
          //dom: 'Blftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });   

      },
      RefreshDatatable() 
      {
        Api.post("/todo/list/team",this.TodoPostList).then((result) => 
        {
          this.ListTodo.status = result.data.status

          if(this.ListTodo.status == "true")
          {
            this.ListTodo.list = result.data.todos;
          }
        })
      },
      DeleteTodo(id) 
      {
  
        const PostDelete = 
        {
          id: id,
          id_session: "",
        }

        PostDelete.id_session = this.MultiSelectMembers.value

        Api.post('/todo/delete', PostDelete).then((result) => 
        {
          if(result.data.status == "true")
          {
            this.$nextTick(() => 
            {
              $('#todo-table').DataTable().destroy()
              this.GetTodo()
            })
          }
        })
      },
      UpdateTodoStatus(id,status) 
      {
        let date = new Date();
        date = Math.floor(date.getTime() / 1000);

        if(status==1) { var TodoStatus=0 }
        if(status==0) { var TodoStatus=1 }

        const PostUpdateStatus = 
        {
          date: date,
          id: id,
          status:TodoStatus,
          id_session: "",
        }
  
        PostUpdateStatus.id_session = this.MultiSelectMembers.value

        Api.post('/todo/update/', PostUpdateStatus).then((result) => 
        {
          if(result.data.status == "true")
          {
            this.$nextTick(() => 
            {
            this.RefreshDatatable()
            })
          }
        })
      },
      SelectedMember(option) 
      {
        this.SelectMember.id_session = option;
        this.MultiSelectProject.options = [];
        this.$refs.multiselect.clear();
        this.GetMembersProject();
      },
      SelectedPostMember() 
      {
        this.TodoPostList.id_session = this.MultiSelectMembers.value
        this.TodoPostList.project = this.MultiSelectProject.value
        this.GetTodo()
      },
      ClearMember(option) 
      {
        this.$refs.multiselect.clear();
        this.MultiSelectProject.options = [];
      }, 
      GetMembers() 
      {
        Api.post("/member/list/", "").then((result) => 
        {
          this.ListMembers.status = result.data.status

          if (this.ListMembers.status == "true") 
          {
            this.ListMembers.list = result.data.member

            for (var i = 0; i < this.ListMembers.list.length; i++) 
            {
              if (this.ListMembers.list[i].description == "Worker") 
              {
                var options = 
                {
                  value: this.ListMembers.list[i].id_session,
                  label: this.ListMembers.list[i].email,
                  icon: this.ListMembers.list[i].picture,
                  color: this.ListMembers.list[i].color
                }
                this.MultiSelectMembers.options.push(options)
              }
            }
          }
        })
      },
      GetMembersProject() 
      {
        Api.get('/projects-members/memberproject/' + this.SelectMember.id_session).then((result) => 
        {
          if (result.data.status == "true") 
          {
            if (result.data.memberprojects != "") 
            {
              var membersprojects = []
              membersprojects = result.data.memberprojects
              let myprojects = [];

              for (var i = 0; i < membersprojects.length; i++) 
              {
                myprojects.push(
                {
                  value: membersprojects[i].id_project,
                  label: membersprojects[i].name
                });
              }

              this.MultiSelectProject.options = myprojects;
            }
          }
        });
      },
    },
    mounted()
    {
      this.GetMembers()
    }
  }
</script>

<style scoped>
</style>
